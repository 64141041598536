"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Product = void 0;
var Product;
(function (Product) {
    Product["BESTFIT_OVERVIEW"] = "BestfitOverview";
    Product["PROFILE"] = "Profile";
    Product["ACCOUNT_PAGE"] = "AccountPage";
    Product["PROFILE_QUESTIONNAIRES"] = "ProfileQuestionnaires";
    Product["EXPERIMENT"] = "Experiment";
    Product["STUDY_PAGE"] = "StudyPage";
    Product["HOME_PAGE"] = "HomePage";
    Product["ORGANISATION_PAGE"] = "OrganisationPage";
    Product["SEARCH_PAGE"] = "SearchPage";
    Product["DISCIPLINE_PAGE"] = "DisciplinePage";
    Product["COUNTRY_TEST"] = "CountryTest";
    Product["PERSONALITY_TEST"] = "PersonalityTest";
    Product["REVIEWS"] = "Reviews";
    Product["DECISION_MAKING_SERVICE"] = "DecisionMakingService";
    Product["GOOGLE_AD_MANAGER"] = "GoogleAdManager";
    Product["ERT"] = "ERT";
    Product["SMT"] = "SMT";
    Product["UNIBUDDY"] = "Unibuddy";
    Product["COUNTRY_DEGREE"] = "CountryDegreePage";
    Product["STUDY_OPTIONS"] = "StudyOptionsPage";
    Product["ARTICLE_PAGE"] = "ArticlePage";
    Product["RECOMMENDATONS"] = "Recommendations";
    Product["SCHOLARSHIP_PAGE"] = "ScholarshipPage";
    Product["COMPARISON_TOOL"] = "ComparisonTool";
    Product["META_RANKING"] = "MetaRanking";
    Product["COUNTRY"] = "Country";
    Product["ACT_DASHBOARD"] = "ACTDashboard";
    Product["MY_JOURNEY"] = "MyJourney";
})(Product = exports.Product || (exports.Product = {}));
